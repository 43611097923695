import { useEquipmentCodeIndMappingVM } from "presentation/hook/EquipmentCodeIndMapping/useEquipmentCodeIndMappingVM";
import { useEquipmentCodeIndMappingTracked } from "presentation/store/EquipmentCodeIndMapping/EquipmentCodeIndMappingProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import EquipmentCodeIndMappingEditPanel from "./RightPanel/EquipmentCodeIndMappingEditPanel";
import EquipmentCodeIndMappingTablePanel from "./Table/EquipmentCodeIndMappingTablePanel";


const EquipmentCodeIndMappingMaintenance: React.FC = () => {
    const [equipmentCodeIndMappingState] = useEquipmentCodeIndMappingTracked();
    const [isLoading, setIsLoading] = useState(true);
    const equipmentCodeIndMappingVM = useEquipmentCodeIndMappingVM();
    const { isShowEditPanel } = equipmentCodeIndMappingState;


    useEffect(() => {
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    equipmentCodeIndMappingVM.loadDropdownOption(),
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            equipmentCodeIndMappingVM.searchAllEquipmentCodeIndMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [equipmentCodeIndMappingVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>

            {!(isShowEditPanel) && <>
                <EquipmentCodeIndMappingTablePanel /></>}
            {(isShowEditPanel) && <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"100%"}
                rightSectionWidth={"0%"}
                leftChildren={<EquipmentCodeIndMappingEditPanel />}
            />}
        </div>
    </>
}

export default memo(EquipmentCodeIndMappingMaintenance);